import { mapHelper } from "@/utils/common.js";

const promiseAuditSts = [
  {
    value: "0",
    label: "未审核",
  },
  {
    value: "1",
    label: "通过",
  },
  {
    value: "2",
    label: "不通过",
  },
];
const { map: promiseAuditStsMap, setOps: promiseAuditStsOps } =
  mapHelper.setMap(promiseAuditSts);

const promiseSts = [
  { value: 1, label: "是" },
  { value: 2, label: "否" },
];
const { map: promiseStsMap, setOps: promiseStsOps } =
  mapHelper.setMap(promiseSts);

const actAuditStsObj = [
  { value: "-1", label: "未参与" },
  { value: "0", label: "未审核" },
  { value: "1", label: "不合格" },
  { value: "2", label: "基本合格" },
  { value: "3", label: "合格" },
];

const { map: actAuditStsObjMap, setOps: actAuditStsObjOps } =
  mapHelper.setMap(actAuditStsObj);
const actScoreObj = [
  { value: "-1", label: "未参与" },
  { value: "0", label: "不合格" },
  { value: "1", label: "不合格" },
  { value: "2", label: "基本合格" },
  { value: "3", label: "合格" },
];
const { map: actScoreObjMap, setOps: actScoreObjOps } =
  mapHelper.setMap(actScoreObj);

const villageList = [
  { value: "1009", label: "紫玉台花苑一期" },
  { value: "1001", label: "东岸名邸" },
  { value: "1002", label: "海志府" },
];
const { map: villageIdsMap, setOps: villageIdsOps } =
  mapHelper.setMap(villageList);

const actAuditSts = [
  { value: 0, label: "待审核" },
  { value: 1, label: "不合格" },
  { value: 2, label: "基本合格" },
  { value: 3, label: "合格" },
];
const { map: actAuditStsMap, setOps: actAuditStsOps } =
  mapHelper.setMap(actAuditSts);
const quScoreSts = [
  { value: 0, label: "待审核" },
  { value: 1, label: "不合格" },
  { value: 2, label: "基本合格" },
  { value: 3, label: "合格" },
];
const { map: quScoreStsMap, setOps: quScoreStsOps } =
  mapHelper.setMap(quScoreSts);
const allScoreSts = [
  { value: 3, label: "合格" },
  { value: 2, label: "基本合格" },
  { value: 1, label: "不合格" },
];
const { map: allScoreStsMap, setOps: allScoreStsOps } =
  mapHelper.setMap(allScoreSts);
export {
  promiseAuditSts,
  promiseAuditStsMap,
  promiseAuditStsOps,
  actAuditStsObj,
  actAuditStsObjMap,
  actScoreObjMap,
  villageList,
  promiseSts,
  actAuditSts,
  quScoreSts,
  allScoreSts,
};
