//合格审核的列表
export const getQualificationList =
  "/gateway/hc-low-carbon/manageapi/garbage/allStsDataList";
//拍照数据审核
export const postActDataAudit =
  "/gateway/hc-low-carbon/manageapi/garbage/actDataAudit";
///承诺书审核
export const promiseAudit =
  "/gateway/hc-low-carbon/manageapi/garbage/promiseAudit";
//垃圾活动主题列表
export const getActivityList =
  "/gateway/hc-low-carbon/manageapi/garbage/topicList";
